import { AnonymousGuard } from './guards/anonymous.guard';
import { UnauthenticatedInterceptor } from './http/unauthenticated.interceptor';
import { AppHelper } from '@core/helpers/app.helper';
import { AuthenticationGuard } from './guards/authentication.guard';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage';
import { BplApiService } from './services/bpl-api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS, JwtInterceptor } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
import { BplBookmarkService } from './services/bpl-bookmark.service';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { RefreshTokenInterceptor } from './http/refresh-token.interceptor';

export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get(AppHelper.getStorageKey('token', 'auth'));
    },
    whitelistedDomains: [AppHelper.apiDomain],
    blacklistedRoutes: [AppHelper.apiDomain + AppHelper.apiPath + 'login', AppHelper.apiDomain + AppHelper.apiPath + 'refresh-token']
  };
}

@NgModule({
  imports: [
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp(AppHelper.firebaseConfig),
    AngularFirestoreModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage]
      }
    })
  ],
  providers: [
    AuthenticationGuard,
    AnonymousGuard,
    BplApiService,
    BplBookmarkService,
    FirebaseX,
    Device,
    JwtInterceptor, // Providing JwtInterceptor allow to inject JwtInterceptor manually into RefreshTokenInterceptor
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthenticatedInterceptor,
      multi: true
    }
  ],

})
export class CoreModule {

  // This is a trick to ensure coreModule is not imported in any place except in AppModule
  constructor(
    @Optional() @SkipSelf() core: CoreModule
  ) {
    if (core) {
      throw new Error('You are trying to import CoreModule twice bastard!  :-( ');
    }
  }
}
