import { ApiUserSingleResponse } from './../interfaces/api-user';
import { Injectable } from '@angular/core';
import { LoginForm } from '../interfaces/login-form';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { ApiLoginResponse } from '../interfaces/api-login-response';
import { BplApiService } from '@core/services/bpl-api.service';
import { AppHelper } from '@core/helpers/app.helper';
import { Router } from '@angular/router';
import { ApiUser } from '../interfaces/api-user';
import { ProfileForm } from '../interfaces/profile-form';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string;
  public user: ApiUser;
  constructor(
    protected http: HttpClient,
    protected storage: Storage,
    protected apiService: BplApiService,
    private router: Router

  ) {

    storage.get(AppHelper.getStorageKey('token', 'auth')).then(res => {
      this.token = res;
    });

    storage.get(AppHelper.getStorageKey('user', 'auth')).then(res => {
      this.user = JSON.parse(res);
    });

  }

  login(data: LoginForm): Observable<any> {
    return new Observable((observer) => {
      this.apiService.post('/scoresheet-login', data).subscribe((res: ApiLoginResponse) => {
        this.manageApiLoginResponse(res).then((values) => { 
          this.router.navigate(['/tabs']);
        });
      },
      err => {
        observer.error(err);
      });
    });
  }

  refresh(): Observable<any> {
    return new Observable((observer) => {
      // console.log('REFRESHING');
      this.storage.get(AppHelper.getStorageKey('refresh_token', 'auth')).then(refreshToken => {
        // console.log('refreshToken', refreshToken);

        if (refreshToken) {
          
          this.apiService.post('/refresh-token', {token: refreshToken}).subscribe((res: ApiLoginResponse) => {
            this.manageApiLoginResponse(res).then((values) => { 
              // console.log(values);  
              observer.next(res);
              observer.complete();
            });
          },
          err => {
            observer.error(err);
          });
        } else {
          observer.error('No refresh_token');
        }
      }).catch(err => {
        observer.error(err);
      });      
    });
  }

  manageApiLoginResponse(res: ApiLoginResponse): Promise<any[]> {
    // console.log(res);

    const promises = [
      this.setToken(res.token.token),
      this.setRefreshToken(res.refresh_token),
      this.setUser(res.user)
    ];
    
    return Promise.all(promises);
   
  }

  logout() {
    this.storage.clear().then(res => {
      this.router.navigate(['/login']);
    });
  }

  changeProfile(id, data: ProfileForm): Observable<any> {
    data.user_status_id = 1;
    return new Observable((observer) => {
      this.apiService.post('/user/' + id, data).subscribe((res: ApiUserSingleResponse) => {
        // console.log(res);

        observer.next(res);
        observer.complete();
      },
      err => {
        observer.error(err);
      });
    });
  }

  register(data: ProfileForm): Observable<any> {
    return new Observable((observer) => {
      this.apiService.post('/register', data).subscribe((res: ApiUserSingleResponse) => {
        // console.log(res);

        observer.next(res);
        observer.complete();
      },
      err => {
        observer.error(err);
      });
    });
  }


  loggedInUser(): ApiUser {
    return this.user;  
  }

  setToken(token: string): Promise<any> {
    this.token = token;
    return this.storage.set(AppHelper.getStorageKey('token', 'auth'), token);
  }

  setRefreshToken(token: string): Promise<any> {
    this.token = token;
    return this.storage.set(AppHelper.getStorageKey('refresh_token', 'auth'), token);
  }

  setUser(user: ApiUser): Promise<any> {
    this.user = user;
    return this.storage.set(AppHelper.getStorageKey('user', 'auth'), JSON.stringify(user));
  }

  isAdminUser() {
    return this.user && (this.user.username === 'sarickx' || this.user.username === 'pthomas');
  }
}
