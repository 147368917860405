import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppHelper } from '@core/helpers/app.helper';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class BplApiService {

  constructor(
    protected http: HttpClient,
    protected storage: Storage,
  ) { }


  getDefaultOptions(paramsObject: object = {}, header: HttpHeaders = null): {headers?: HttpHeaders, params?: HttpParams} {
    const headers = header ? header : new HttpHeaders();
    let params = new HttpParams();

    for (const key in paramsObject) {
      if (paramsObject.hasOwnProperty(key)) {
        const value = paramsObject[key];
        params = params.set(key, value);
      }
    }

    return { headers, params };
  }

  get(url: string, params: object = {}, header: HttpHeaders = null): Observable<any> {
    const options = this.getDefaultOptions(params, header);
    return this.http.get( AppHelper.baseUrl + url, {params: options.params});
  }

  post(url: string, body: object = {}, header: HttpHeaders = null): Observable<any> {
    const options = this.getDefaultOptions({}, header);
    return this.http.post( AppHelper.baseUrl + url, body, options);
  }

  delete(url: string, body: any = {}, header: HttpHeaders = null): Observable<any> {
    body._method = 'delete';
    return this.post(url, body, header );
  }

  async locationHref(url, addTokenParam = true) {

    let target = `${AppHelper.baseUrl}${url}`;
    if (addTokenParam) {
      const token  = await this.storage.get(AppHelper.getStorageKey('token', 'auth'));
      target = `${target}?token=${token}`;
    }
    window.location.replace(target); // TODO manage this in other way to control when it finish
    return true;
  }

}
