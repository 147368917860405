import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {

  private history = [];

  constructor(
    private router: Router
  ) {}

  public loadRouting(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history = [...this.history, event.urlAfterRedirects];
      }
    });

  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(defaultUrl: string = ''): string {
    const result =  this.history[this.history.length - 2] || defaultUrl;
    delete(this.history[this.history.length - 1]);
    return result;

  }

  public cleanHistory() {
    this.history = [];
  }
}
