import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AppHelper } from '@core/helpers/app.helper';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate, CanLoad  {

  private tokenStorageKey: string;
  constructor(
    private storage: Storage,
    private router: Router
  ) {
    this.tokenStorageKey = AppHelper.getStorageKey('token', 'auth');
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> | boolean {
    return this.checkAutenticationGuard();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.checkAutenticationGuard();

  }

  checkAutenticationGuard(): Promise<boolean> | boolean {
    return this.storage.get(this.tokenStorageKey).then((token) => {
      if (token) {
        this.router.navigate(['/tabs']);
        return false;
      }
      return true;
    });
  }
}
