import { Injectable } from '@angular/core';
// import { Firebase } from '@ionic-native/firebase/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

import { AngularFirestore } from '@angular/fire/firestore';
import { Platform } from '@ionic/angular';
import { AppHelper } from '@core/helpers/app.helper';
import { BplApiService } from './bpl-api.service';
import { ApiDeviceResponse } from '@core/interfaces/api-device-response';
import { Storage } from '@ionic/storage';
import { Device } from '@ionic-native/device/ngx/index';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FCMService {

  constructor(
    private firebase: FirebaseX,
    private afs: AngularFirestore,
    private platform: Platform,
    protected storage: Storage,
    private device: Device,
    protected apiService: BplApiService,


  ) {}

  async getToken() {
    let token = null;
    if (this.device.platform) {
      if (this.platform.is('android')) {
        token = await this.firebase.getToken();
        console.log('android Token:', token);
      }

      if (this.platform.is('ios')) {
        token = await this.firebase.getToken();
        await this.firebase.grantPermission();
        console.log('ios Token:', token);

      }
    }

    console.log('global Token:', token);

    this.firebaseSaveToken(token);
    this.localSaveToken(token);
    this.saveDevice(token);
  }

  private localSaveToken(token: string) {
    return this.storage.set(AppHelper.getStorageKey('fire_token', 'fcm'), token);
  }

  private saveDevice(token: string) {

    let data;

    if (this.device.platform) {
      data = {
        token: token,
        model: this.device.model, // "Aquaris U",
        platform: this.device.platform, // "Android",
        uuid: this.device.uuid, // "ff076acbcd8c65b5",
        version: this.device.version, // "7.1.1",
        manufacturer: this.device.manufacturer, // "bq",
        serial: this.device.serial, // "CT035702",
      };
    } else {
      data = AppHelper.mockDevice;
    } 
    
    console.log('DataToSaveDevice', data);

    if (this.validateDeviceData(data)) {
      this.apiService.post('/devices', data).subscribe((res: ApiDeviceResponse) => {
        console.log(res);
      });
    }
    
  }

  private validateDeviceData(data) {
    if (!data) {
      return false;
    }

    return data && data.uuid && data.token;
  }

  private firebaseSaveToken(token: string) {
    if (!token) { return; }

    const devicesRef = this.afs.collection('devices');

    const data = {
      token,
      userId: 'testUserId'
    };

    return devicesRef.doc(token).set(data);
  }

  onNotifications() {
    return this.firebase.onMessageReceived(); 
  
  }
}
