import { AppHelper } from '@core/helpers/app.helper';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UnauthenticatedInterceptor implements HttpInterceptor {
    constructor( private storage: Storage ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (
                    (error.hasOwnProperty('status') && error.status === 401) ||
                    (error.hasOwnProperty('error') && error.error === 'token_invalid')
                ) {
                    // console.log({fromCathErrorStorage: AppHelper.getStorageKey('token', 'auth')});
                    this.storage.remove(AppHelper.getStorageKey('token', 'auth')).then(res => {
                        // console.log(res);
                    });
                }
                return throwError(error);
            }));
    }
}