import { AuthService } from '@modules/auth/services/auth.service';
import { Component } from '@angular/core';

import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationHistoryService } from '@core/services/navigation-history.service';
import { FCMService } from '@core/services/fcm.service';
import { Device } from '@ionic-native/device/ngx/index';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'bps-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navHistory: NavigationHistoryService,
    private fcm: FCMService,
    private device: Device,
    private router: Router,
    private authService: AuthService,

    public toastController: ToastController


  ) {
    this.initializeApp();
  }

  private async presentToast(title, message, pageUrl = null, pageState = {}) {

    const buttons = [
    ];

    if (pageUrl) {
      buttons.push({
        side: 'end',
        text: 'View',
        handler: () => {
          this.navigateToPage(pageUrl, pageState);
        }
      });
    }

    buttons.push({
      text: 'Done',
      role: 'cancel',
      handler: () => {
        
      }
    });

    const toast = await this.toastController.create({
      header: title,
      message,
      buttons: buttons,
    });

    toast.present();
  }

  private notificationSetup() {
    // console.log('device:', this.device);
    if ( this.device.platform) {
      // this.fcm.getToken();
      this.fcm.onNotifications().subscribe(msg => {
        // console.log('onNotifications msg', msg);

        if (this.platform.is('ios')) {
          this.presentToast(msg.aps.alert.title, msg.aps.alert.body, msg.pageUrl, msg.state);

        } else {

          const state =  msg.state ? msg.state : {};
          const pageUrl = msg.pageUrl ? msg.pageUrl :  null;

          if (msg.tap) {
            if (pageUrl) {
              this.navigateToPage(pageUrl, state);
            }
          } else {

            this.presentToast(msg.title, msg.body, pageUrl, state);
          }
        }
      });
      
    }
  }

  navigateToPage(page: string, state: object = {}) {
    const navigationExtras: NavigationExtras = {
      state: state
    };
    this.router.navigate([page], navigationExtras);

  }

  refreshToken() {
    this.authService.refresh().subscribe(res => {
      // console.log('refresh_token', res);
    });
  }

  initializeApp() {
    this.refreshToken();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.navHistory.loadRouting();
      this.notificationSetup();

    });
  }
}
