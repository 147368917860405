import { AnonymousGuard } from '@core/guards/anonymous.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
// import { AuthenticationGuard } from '@core/guards/authentication.guard';

const defaultRoute = 'tabs';
const routes: Routes = [
  {
    path: 'auth',
    canLoad: [AnonymousGuard],
    canActivate: [AnonymousGuard],
    loadChildren: () => import('@modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'settings',
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('@modules/user-settings/user-settings.module').then(m => m.UserSettingsModule)
  },
  { 
    path: 'tabs', 
    loadChildren: () => import('@app/tabs/tabs.module').then(m => m.TabsScoresheetPageModule)
  },
  {
    path: '**',
    redirectTo: defaultRoute,
    pathMatch: 'full'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
