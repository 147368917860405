import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BplApiService } from '@core/services/bpl-api.service';

@Injectable({
  providedIn: 'root'
})
export class BplBookmarkService {

  constructor(
    private apiCtrl: BplApiService,

  ) { }

  newBookMark(bookmarkable_type: string, bookmarkable_id: number): Observable<any> {
    return this.apiCtrl.post('/bookmarks', {
      bookmarkable_type: bookmarkable_type,
      bookmarkable_id: bookmarkable_id
    });
  }

  removeBookMark(id: number): Observable<any> {
    return this.apiCtrl.delete('/bookmarks/' + id);
  }

}
