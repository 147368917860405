import { Environment } from '@core/interfaces/environment';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const apiScheme = 'http';
// const apiDomain = 'localhost:8888';
// const apiPath = '/lms_league_ws/public/api/v1';
// const imagePath = '/lms_league_ws/public/img';

const apiScheme = 'https';
const apiDomain = 'tst.gestion.awbb.be';
const apiPath = '/lms_league_ws/public/api/v1';
const imagePath = '/lms_league_ws/public/img';

const wpApiScheme = 'https';
const wpApiDomain = 'tst.awbb.be';
const wpApiPath = '/wp-json/wp/v2';

export const environment: Environment = {
  production: true,
  syncEnabled: true,
  storageKey: 'bps_app_',
  baseUrl: apiScheme + '://' + apiDomain + apiPath,
  imgBaseUrl: apiScheme + '://' + apiDomain + imagePath,
  apiScheme: apiScheme,
  apiDomain: apiDomain,
  apiPath: apiPath,

  wpBaseUrl: wpApiScheme + '://' + wpApiDomain + wpApiPath,
  wpApiScheme: wpApiScheme,
  wpApiDomain: wpApiDomain,
  wpApiPath: wpApiPath,
  wpDefaultImgUrl: wpApiScheme + '://' + wpApiDomain + '/wp-content/uploads/2017/03/default_social.jpg',
  firebase: {  
    apiKey: 'AIzaSyAOr_5u6bImgEm60151_z1KKoxDl6RrMKQ',
    authDomain: 'ion-awbb-d8580.firebaseapp.com',
    databaseURL: 'https://ion-awbb-d8580.firebaseio.com',
    projectId: 'ion-awbb-d8580',
    storageBucket: 'ion-awbb-d8580.appspot.com',
    messagingSenderId: '1036166935903',
    appId: '1:1036166935903:web:3d4175890a68b380df72ea',
    measurementId: 'G-QSW79MMTET'
  },
  mockDevice: {
    // tslint:disable-next-line: max-line-length
    token: 'cNvs70g2FhM:APA91bFv90oqDDf53Nb0Oe23ydXJJQR5R-XVZwKtWyGTwqgw7xhVAU6qPm0J29kzVLPJUH-yOfYpSEtnLS_aIjtWJmJYwbf8oJQ9c_DO4noB1KowVfKQu-oIIuQ_DPVNCYPMXIALGZyu',
    model: 'Aquaris U',
    platform: 'Android',
    uuid: 'ff076acbcd8c65b5',
    version: '7.1.1',
    manufacturer: 'bq',
    serial: 'CT035702'    
  },
  committeeRoleTypeId: 5,
  allowedCommitteeRoles: ['PRE', 'SEC', 'TRE'],
  rankingColumns: [
    {name: 'wins', label: 'W'}, 
    // {name: 'draws', label: 'D'}, 
    {name: 'losses', label: 'L'},
    {name: 'points', label: 'PTS'},
  ],

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
