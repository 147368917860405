import { environment } from '@env/environment';
import { RankingColumnOption } from '@core/interfaces/ranking-column-option';
import { FirebaseConfig } from '@core/interfaces/firebase';
import { MockDevice } from '@core/interfaces/mock-device';

export class AppHelper {

  public static storageKey: string = environment.storageKey;
  public static baseUrl: string = environment.baseUrl;
  public static apiDomain: string = environment.apiDomain;
  public static apiScheme: string = environment.apiScheme;
  public static apiPath: string = environment.apiPath;
  public static apiImgUploadsUrl: string = environment.imgBaseUrl;

  public static wpBaseUrl: string = environment.wpBaseUrl;
  public static wpApiDomain: string = environment.wpApiDomain;
  public static wpApiScheme: string = environment.wpApiScheme;
  public static wpApiPath: string = environment.wpApiPath;
  public static wpDefaultImgUrl: string = environment.wpDefaultImgUrl;


  public static committeeRoleTypeId: number = environment.committeeRoleTypeId;
  public static allowedCommitteeRoles: string[] = environment.allowedCommitteeRoles;
  public static rankingColumns: RankingColumnOption[] = environment.rankingColumns;
  public static firebaseConfig: FirebaseConfig = environment.firebase;
  public static mockDevice: MockDevice = environment.mockDevice;
  public static syncEnabled: boolean = environment.syncEnabled;
  
  constructor() { }

  public static getStorageKey(name: string, mod: string | null = null): string {
    const modKey = mod ? mod + '_' : '';
    return AppHelper.storageKey + modKey + name;
  }

}
